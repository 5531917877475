<template>
  <b-table-simple
    bordered
    responsive
    sticky-header
    style="max-height: 65vh; font-size: 12px"
  >
    <colgroup>
      <col style="width: 130px">
      <col style="width: 150px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 150px">
    </colgroup>
    <b-thead head-variant="light">
      <b-tr>
        <b-th
          style="text-align: center;"
        >일자</b-th>
        <b-th
          style="text-align: center;"
        >진료과</b-th>
        <b-th
          style="text-align: center;"
        >{{ searchType === 2 ? '전체처수' : '전체수' }}</b-th>
        <b-th
          style="text-align: center;"
        >{{ searchType === 2 ? '기등록처수' : '기등록수' }}</b-th>
        <b-th
          style="text-align: center;"
        >{{ searchType === 2 ? '노출처수' : '노출수' }}</b-th>
        <b-th
          style="text-align: center;"
        >클릭수</b-th>
        <b-th
          style="text-align: center;"
        >클릭수<br>(중복제거)</b-th>
        <b-th
          v-if="searchType === 2"
          style="text-align: center;"
        >등록처수<br>(모아보기)</b-th>
        <b-th
          v-else
          style="text-align: center;"
        >등록수<br>(모아보기)</b-th>
        <b-th
          style="text-align: center;"
        >게시 잔여기간</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="daily in data">
        <template v-for="(dept, index) in daily.landingDepts">
          <b-tr
            :key="`daily-${daily.liveInfoId}-${daily.execDate}-${index}`"
          >
            <b-td
              v-if="index === 0"
              :rowspan="daily.landingDepts.length"
              style="text-align: center;"
            >
              {{ daily.execDate | $dateFormatter('MM월 DD일') }}
            </b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.medicalDeptName }}</b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.wasRegCount + dept.unRegCount | $numberFormatter }}</b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.wasRegCount | $numberFormatter }}</b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.unRegCount | $numberFormatter }} </b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.totalClickCount | $numberFormatter }} </b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.groupClickCount | $numberFormatter }} </b-td>
            <b-td
              style="text-align: center;"
            >{{ dept.landingRegCount | $numberFormatter }} </b-td>
            <b-td
              v-if="index === 0"
              :rowspan="daily.landingDepts.length"
              style="text-align: center;"
            >
              {{ getRemainDate(daily.endDateStr)+'일' }}
            </b-td>
          </b-tr>
        </template>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    searchType: {
      type: Number,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })

    const getRemainDate = toDateStr => {
      const todayDate = dayjs()
      const result = dayjs(toDateStr).diff(todayDate, 'day') + 1
      if (result > 0) { return result }
      return 0
    }

    return {
      getRemainDate,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}
</style>
