<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제약사"
            >
              <b-form-input
                v-model="drugLandingData.clientName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="제목"
            >
              <b-form-input
                v-model="drugLandingData.title"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="제품명"
            >
              <b-form-input
                v-model="drugLandingData.drugName"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="청구코드"
            >
              <b-form-input
                v-model="drugLandingData.condDrugCodes"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="게시기간"
            >
              <b-form-input
                v-model="drugLandingData.liveDate"
                readonly
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-col
          cols="12"
          md="4"
        />
      </div>
    </b-card>

    <b-card no-body>
      <drugLanding-click-View
        :live-info-id="liveInfoId"
        :start-date="new Date(drugLandingData.startDate)"
      />
    </b-card>
  </div>
</template>
<script>

import axios from '@axios'
import { ref, onMounted } from "@vue/composition-api"
import { getUserData } from '@/auth/utils'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useToast } from "vue-toastification/composition"
import drugLandingClickView from './DrugLandingClickView.vue'

export default {
  components: {
    drugLandingClickView,
  },
  computed: {

  },
  setup() {
    onMounted(() => {
      fetchDrugLanding()
    })
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const drugLandingData = ref({})
    const accessCode = ref(null)
    const liveInfoId = ref(0)

    const fetchDrugLanding = () => {
      const userData = getUserData()
      accessCode.value = userData.accessCode
      axios.get(`/cl/druglanding/${accessCode.value}`)
        .then(rs => {
          const { data } = rs
          drugLandingData.value = data
          liveInfoId.value = data.liveInfoId
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    return {
      drugLandingData,
      liveInfoId,
      fetchDrugLanding,
    }
  },
}
</script>

<style lang="scss">
#drugInfoPreview .modal-dialog {
  max-width: 1150px;
}
</style>
