<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="게시시작일(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="게시시작일(To)"
              style="width: 250px"
            />
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <daily-grid
        :data="drugLandingData"
        :search-type="countingGroup"
      />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import DailyGrid from './grid/DailyGrid.vue'

export default {
  components: {
    DailyGrid,
  },
  props: {
    liveInfoId: {
      type: Number,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
  },
  setup(props) {
    const query = ref({
      liveInfoId: null,
      countingGroup: 2,
      searchStartDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'),
      searchEndDate: dayjs().format('YYYY-MM-DD'),
    })

    watch(() => props.liveInfoId, newVal => {
      query.value.liveInfoId = newVal
    })
    // watch(() => props.startDate, newVal => {
    //   query.value.searchStartDate = newVal
    // })
    watch(query.value, () => {
      if (isFirstLoad.value) {
        isFirstLoad.value = 0
        fetchData()
      }
    })

    const toast = useToast()

    const isFirstLoad = ref(1)
    const countingGroup = ref(2)
    const drugLandingData = ref([])

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      if (Math.abs(frDate.diff(toDate, 'day')) > 30) {
        toast({
          component: ToastificationContent,
          props: {
            title: '조회 기간은 최대 30일을 넘을 수 없습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      axios.get('/cl/druglanding/statistics', {
        params: {
          liveInfoId: query.value.liveInfoId,

          countingGroup: query.value.countingGroup,
          startDate: query.value.searchStartDate,
          endDate: query.value.searchEndDate,
        },
      })
        .then(rs => {
          countingGroup.value = query.value.countingGroup
          drugLandingData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회 중 오류가 발생하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "게시 기간", key: "execDate", width: 25 },
        { header: "진료과", key: "medicalDeptName", width: 20 },
        {
          header: countingGroup.value === 2 ? "전체처수" : "전체수", key: "totalRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "기등록처수" : "기등록수", key: "wasRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "노출처수" : "노출수", key: "unRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "클릭수", key: "totalClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "클릭수(중복제거)", key: "groupClickCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: countingGroup.value === 2 ? "등록처수(모아보기)" : "등록수(모아보기)", key: "landingRegCount", width: 15, style: { numFmt: '#,##0' },
        },
        {
          header: "게시 잔여기간", key: "remainDate", width: 15, style: { numFmt: '#,##0' },
        },
      ]

      let startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      drugLandingData.value.map(e => {
        e.landingDepts.map(x => worksheet.addRow({
          execDate: `${dayjs(e.execDate).format('YYYY-MM-DD')}`,
          medicalDeptName: x.medicalDeptName,
          totalRegCount: x.wasRegCount + x.unRegCount,
          wasRegCount: x.wasRegCount,
          unRegCount: x.unRegCount,
          totalClickCount: x.totalClickCount,
          groupClickCount: x.groupClickCount,
          landingRegCount: x.landingRegCount,
          remainDate: `${getRemainDate(e.endDateStr)}일`,
        }))

        endRow += e.landingDepts.length - 1

        worksheet.mergeCells(`O${startRow}:O${endRow}`)

        startRow = endRow + 1
        endRow = startRow
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "간편처방등록 통계(일별).xlsx")
    }

    const getRemainDate = toDateStr => {
      const todayDate = dayjs()
      const result = dayjs(toDateStr).diff(todayDate, 'day') + 1
      if (result > 0) { return result }
      return 0
    }

    return {
      query,
      isFirstLoad,
      countingGroup,
      drugLandingData,
      fetchData,
      excelDownload,
      getRemainDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
