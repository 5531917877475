var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "제약사" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.drugLandingData.clientName,
                            callback: function ($$v) {
                              _vm.$set(_vm.drugLandingData, "clientName", $$v)
                            },
                            expression: "drugLandingData.clientName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "8" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "제목" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.drugLandingData.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.drugLandingData, "title", $$v)
                            },
                            expression: "drugLandingData.title",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "제품명" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.drugLandingData.drugName,
                            callback: function ($$v) {
                              _vm.$set(_vm.drugLandingData, "drugName", $$v)
                            },
                            expression: "drugLandingData.drugName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "청구코드" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.drugLandingData.condDrugCodes,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.drugLandingData,
                                "condDrugCodes",
                                $$v
                              )
                            },
                            expression: "drugLandingData.condDrugCodes",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "게시기간" } },
                      [
                        _c("b-form-input", {
                          attrs: { readonly: "" },
                          model: {
                            value: _vm.drugLandingData.liveDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.drugLandingData, "liveDate", $$v)
                            },
                            expression: "drugLandingData.liveDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-col", { attrs: { cols: "12", md: "4" } }),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("drugLanding-click-View", {
            attrs: {
              "live-info-id": _vm.liveInfoId,
              "start-date": new Date(_vm.drugLandingData.startDate),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }